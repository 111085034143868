import axios from "axios";
const ENDPOINT = "/api/v1/cache";

const clear = async payload => {
  return axios.post(ENDPOINT + "/clear", payload);
};

const optimize = async payload => {
  return axios.post(ENDPOINT + "/optimize", payload);
};

const routes = async payload => {
  return axios.post(ENDPOINT + "/routes", payload);
};



export { clear, optimize, routes };
