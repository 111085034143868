<template>
  <div class="dashboard">
    <v-card-actions class="pa-0">
      <page-title :title="title" />

      <v-spacer></v-spacer>
      <AddButton
        :create-function="clearCache"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'CLEAR CACHE'"
      />
      <AddButton
        :create-function="clearRoutes"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'CLEAR ROUTES'"
      />

      <AddButton
        :create-function="optimize"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'OPTIMIZE'"
      />
    </v-card-actions>
  </div>
</template>

<script>
import * as cache from "../cache-configurations/services";

export default {
  name: "AppCacheConfig",
  data: () => {
    return {
      title: "Application cache Management",
    };
  },
  props: {},

  methods: {
    clearCache() {
      return cache.clear();
    },

    clearRoutes() {
      return cache.routes();
    },

    optimize() {
      return cache.optimize();
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
